import React from "react" //, { useState }
//import {AllData} from "../data/data.js";
import { graphql, Link } from "gatsby"
import {  Col, Container, Row } from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import $ from 'jquery'
//import $ from 'jquery-slim'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import data_yaml from "../data/project_data/data.yaml"

/*
if(typeof(window) !== 'undefined'){
  require('slick-carousel/slick/slick');
}
*/

class X extends React.Component{
  constructor(data){
    super(data)
    this.data_yaml = data_yaml;
    //console.log('Data YAML', data_yaml)

    //const [slidesToShow, setSlideToShow] = useState(3)
    this.state = {
      project_info: null,
      nav1: null,
      nav2: null  
    }

    this.data = data
    this.projectName = ""; //"projects/1"
    this.projectInfo = {other_projects_title: "", all_projects_title: ""};
    if(data.pageContext.projectName){
      this.projectName = "projects/" + data.pageContext.projectName;
    }

    if(data.pageContext.projectInfo){
      this.projectInfo = data.pageContext.projectInfo;
    }

    //console.log('All data', AllData);
    //console.log(data.pageContext.projectInfo)
    
    this.detail_images = []
    this.project_thumbnail_images = data.data.project_thumbnail_images.nodes
    this.related_thumbnail_images = data.data.related_thumbnail_images.nodes
    //this.detail_thumbnail_images = data.data.detail_thumbnail_images.nodes
    
    data.data.detail_images.nodes.forEach(x => {
      if(x.relativeDirectory === this.projectName){
        this.detail_images.push(x)
      }
    })
  
    this.filter_images = [];
    this.thumbnail_images = [];
    //this.filtered_detail_thumbnail_images = [];
    if(this.projectName !== ""){
      this.filter_images = this.detail_images.filter(x=> x.relativeDirectory === this.projectName);
      this.thumbnail_images = this.project_thumbnail_images.filter(x=> x.relativeDirectory === this.projectName);
    }

  }


  componentDidMount(){
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });

    /*
    console.log('context', this.data.pageContext);
    console.log('folder', this.data.pageContext.folder);
    console.log('project name', this.data.pageContext.projectName);
    console.log(this.filter_images);
    */

    let project_name = this.data.pageContext.projectName;
    let folder = this.data.pageContext.folder;
    if(project_name && folder){
      let project_info = this.data_yaml.all_projects[folder].sub_projects[project_name];
      //console.log('project info', project_info);      
      this.setState({project_info: project_info});
    }
  }

  getRelatedProjects(){
    return <Container className={this.data.pageContext.projectName ? "" : "mt-5"} fluid>
          <h5>{this.data.pageContext.projectName ? this.projectInfo.other_projects_title: this.projectInfo.all_projects_title}</h5>
          <Row>
            {this.related_thumbnail_images.map(x => {
              const image = getImage(x.childImageSharp)
              return <Col style={{marginBottom: '20px'}} className="related_project_column" key={x.id} xs={6} md={4} lg={4}>
                <Link className="related_project_image" to={`/${this.data.pageContext.folder}/${x.name}`}>
                    <GatsbyImage className="thumbnail" imgStyle={{maxWidth: '100%'}} image={image} alt={x.name} title={x.name.replace(/_/g, " ")} />
                </Link>
                <Link className="related_project_text" to={`/${this.data.pageContext.folder}/${x.name}`}>
                  <div className="project_name">{x.name.replace(/_/g, " ")}</div>
                </Link>
              </Col>
            }
            )}
          </Row>
        </Container>
  }

  getProjectDetails(){

    if(this.project_thumbnail_images.length > 0){
      if(this.projectName !== "" && this.filter_images.length > 0){
        let detail_settings = {
          dots: false,
          speed: 500,
          focusOnSelect: false,
          slidesToShow: 6,
          slidesToScroll: 4,
          initialSlide: 0,      
          infinite: true,
          responsive: [
            {
              breakpoint: 1140,
              settings: {
                slidesToShow: 6
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5
              }
            },
            {
              breakpoint: 924,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
          ]
        };

        let thumbnailHeights = [];
        return <>
          <Container className="desktop_mode">
              <Row id="desktop_carousel_images" className="carousel_images row">
                <Col md={{span: 12, offset: 0}} lg={{ span: 12, offset: 0 }}>
                  <Slider ref={slider => (this.slider1 = slider)} arrows={false} adaptiveHeight={true}>
                    {this.filter_images.map(x => {
                          const image = getImage(x.childImageSharp)
                          return <div key={x.id + "_main_image_xx"} className="image_div text-center">
                            <GatsbyImage imgStyle={{maxWidth: '100%', maxHeight: 'calc(100vh - 201px)'}} image={image} alt={x.name} title={""} loading="eager" />
                            </div>
                        }
                      )}
                  </Slider>
                </Col>
              </Row>
      
                <Row style={{minHeight: '80px'}}>
                    <Col md={{span: 6}}>
                      <Slider ref={slider => (this.slider2 = slider)} {...detail_settings}  >
                        {
                        
                        this.thumbnail_images.map((x, innerIndex) => {
                          thumbnailHeights.push(parseInt(x.childImageSharp.gatsbyImageData.height));
                            const image = getImage(x.childImageSharp)
                            return <div key={x.id + '_xx_' + Math.random(1000)} className={`mr-2 thumbnail`} >
                              <GatsbyImage onClick={() => {
                                //alert(innerIndex);
                                //console.log(this.state.nav1, innerIndex);
                                this.state.nav1.slickGoTo(innerIndex);
                              }} objectFit="contain" style={{width: '80px', height: '80px', objectFit: "contain"}} image={image} alt={x.name} title={""} loading="eager" />
                              </div>
                          }
                        )}
                      </Slider>
                    </Col>
                    <Col md={{span: 6}}>
                        {this.state.project_info ? <div className="project_info">
                          <h1 style={{marginTop: (80 - Math.min(...thumbnailHeights) - 12)/2 + 'px'}}>{this.state.project_info.title}</h1>
                          <div>{this.state.project_info.description}</div>
                        </div> : null}
                      </Col>
                </Row>
            </Container>
            <Container className="mobile_mode">
              {this.filter_images.map((x, index) => {
                        const image = getImage(x.childImageSharp)
                        return <div key={x.id + "_main_image_mobile"} className="image_div">
                          <GatsbyImage imgStyle={{maxWidth: '100%'}} image={image} alt={x.name} title={x.name} />
                          { index === 0 && this.state.project_info ? <div className="project_info mt1">
                            <h1>{this.state.project_info.title}</h1>
                            <div>{this.state.project_info.description}</div>
                          </div> : null}
                          </div>
                      }
              )}
            </Container>
            <hr/>
            {this.getRelatedProjects()}
          </>;          
      }
      else{
        return this.getRelatedProjects()
      }
    }
    else{
      return <h1 className="mt-4">Photography in progress; please check back soon.</h1>
    }
  }
  
  render(){
    //console.log('context', this.data.pageContext)
    let pageTitle = (this.data.pageContext.projectName ? this.data.pageContext.projectName.replace(/_/g, ' ') + ' | ': '') + this.projectInfo.all_projects_title
    //data.pageContext.pageTitle
    return <Layout pageInfo={{ pageName: this.data.pageContext.folder, pageTitle: pageTitle }}>
    <SEO title={pageTitle} keywords={[pageTitle]} />
      <Container className="images_page container-full mb-4">
        {this.getProjectDetails()}
    </Container>
    </Layout>
  }
}

export const Data = graphql`
  query ($folder: String, $projectName: String) {
    detail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {ne: "thumbnails"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData(height: 1000)
        }
      }
    }
    
    project_thumbnail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {ne: "thumbnails"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData(width: 80)
        }
      }
    }

    related_thumbnail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, name: {ne:$projectName}, relativeDirectory: {eq: "thumbnails"}}, 
    sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData(width: 350)
        }
      }
    }
  }

`
/*
detail_thumbnail_images: allFile(filter: {sourceInstanceName: {eq: $folder}, relativeDirectory: {ne: "thumbnails"}}, sort: {fields: [name], order: ASC}) {
      nodes {
        id
        name
        relativePath
        sourceInstanceName
        relativeDirectory
        childImageSharp {
          gatsbyImageData(height: 80, width: 80)
        }
      }
    }
    */

export default X